import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Marketplace Website Template | Marketplace Web Design | Uizard"
    category="Website Templates"
    url="/templates/website-templates/marketplace-website/"
    metaDescription="Create your own marketplace web app or website with Sellerlist, a web design template designed to streamline your design project. Sign up now."
    description="
    h2:Sellerlist: our marketplace website design template
    <br/>
    Create your own marketplace web app or website with Sellerlist, a <a:https://uizard.io/templates/website-templates/>web design template</a> designed to streamline your design project. As with all our web UI mockups, Sellerlist, comes with all the must-have screens included. The design also reflects the core UX considerations of the most popular online marketplaces out there.
    <br/>
    h3:Web marketplace design made easy
    <br/>
    Whether you are looking to design the next eBay or you've come up with a brand-new marketplace idea with a fantastic USP, Uizard is here to help you bring your idea to life as quickly as possible. Unlike other <a:https://uizard.io/prototyping/>UX prototyping tools</a>, Uizard is built for ease of use, meaning you can have your design completed and handed off to a developer in next to no time.
    <br/>
    h3:Collaborative prototyping like never before
    <br/>
    Working alone or working with a team, it's super easy to share your design with stakeholders both internal and external. Share your finished design with teammates or with other stakeholders for feedback, or work in collaboration on your next iteration to really perfect your ideas.
    "
    pages={[
      "A sleek home screen with item search bar and core categories",
      "Sell item screen including form data and image upload mockups",
      "User profile screen with item management carousel",
      "Basket screen for item management and payment",
    ]}
    projectCode="rg5OwXvRmBuV7ymE6rEe"
    img1={data.image1.childImageSharp}
    img1alt="marketplace website design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="marketplace website design template shirts category"
    img3={data.image3.childImageSharp}
    img3alt="marketplace website design template product screen"
    img4={data.image4.childImageSharp}
    img4alt="marketplace website design template user checkout screen"
    img5={data.image5.childImageSharp}
    img5alt="marketplace website design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/marketplace-website/marketplace-website-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/marketplace-website/marketplace-website-shirts.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/marketplace-website/marketplace-website-product.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/marketplace-website/marketplace-website-checkout.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/marketplace-website/marketplace-website-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
